import React from 'react';
import { ValidationSchema } from './Context';

const DummyFunction = () => {
  return null;
};

export default DummyFunction;

export const initialValues: ValidationSchema = {
  // first step
  firstName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  lastName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
  },
  email: {
    value: '',
    error: '',
    validate: 'email',
  },
  gender: {
    value: '',
    error: '',
    validate: 'text',
  },
  date: {
    value: {},
    error: '',
  },
  time: {
    value: '',
    error: '',
  },
  birthPlace: {
    value: {},
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    // maxLength: 20,
    helperText: 'Custom error message',
  },
  marriageStatus: {
    value: ' ',
    error: '',
    validate: 'select',
  },
  homeAddress: {
    value: {},
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    // maxLength: 20,
    helperText: 'Custom error message',
  },
  aboutMe: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 2,
    // maxLength: 20,
    helperText: 'Custom error message',
  },
  gautra: {
    value: '',
    error: '',
    validate: 'text',
  },
  caste: {
    value: '',
    error: '',
    validate: 'select',
  },
  manglik: {
    value: ' ',
    error: '',
    validate: 'select',
  },
  bloodGroup: {
    value: ' ',
    error: '',
    validate: 'text',
  },
  height: {
    value: '',
    error: '',
    validate: 'text',
    // minLength: 3,
    maxLength: 20,
  },
  weight: {
    value: '',
    error: '',
    validate: 'text',
    // minLength: 3,
    maxLength: 20,
  },
  color: {
    value: ' ',
    error: '',
    validate: 'text',
  },

  // second step

  degree: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  field: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  college: {
    value: '',
    error: '',
    required: true,
    helperText: 'Custom error message',
  },
  passingYear: {
    value: '',
    error: '',
    required: true,
    helperText: 'Custom error message',
  },
  jobDesignation: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  companyName: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  jobAddress: {
    value: {},
    error: '',
    required: false,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  jobType: {
    value: ' ',
    error: '',
    validate: 'select',
  },
  salary: {
    value: '',
    error: '',
  },

  city: {
    value: {},
    error: '',
    validate: 'text',
    minLength: 3,
    maxLength: 20,
  },
  agreement: {
    value: false,
    error: '',
    required: true,
    validate: 'checkbox',
    helperText: 'Please accept our terms and conditions',
  },
  phone: {
    value: '',
    error: '',
    validate: 'phone',
    maxLength: 15,
  },
  //4th step
  uploadPhoto: {
    value: [],
  },

  uploadKundli: {
    value: [],
  },

  graph_node_id: {
    value: '',
  },
  // Independent Profile input fields
  phoneNumber: {
    value: [],
    error: '',
    validate: 'phone',
    minLength: 10,
    // maxLength: 10,
  },

  fatherName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },

  village: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  hobbies: {
    value: [],
    error: '',
  },
};
